import React from "react"
import LayoutA7 from "../../components/layout-a7"
import raceInfoStyles from "./a7_raceInfo.module.css"

export default class A7RaceInfo extends React.Component {
  render() {
    console.log("this.props:",this.props)
    const infotype = this.props.pageContext.element.infotype
    console.log("infotype",infotype)
    const topTitle=infotype==='rules'?'竞赛规程':'赛事路线'
    const raceName='2019簇格马拉松竞赛规程'
    const contents=this.props.pageContext.element.contents

    const contentlist=contents.map((content,index)=>
      <div key={index} className={raceInfoStyles.content}>
        <p>{content.title}</p>
        {content.content.map((item,index)=>
          <div key={index}>
            {item.type==='text'&&<p key={index}>{item.text}</p>}
            {item.type==='img'&&<img alt="hello" key={index}  src={item.src}/>}
          </div>

        )}
      </div>)
    return(
      <LayoutA7>
        <div className={raceInfoStyles.raceinfo}>
          <div className={raceInfoStyles.container}>
            <div className={raceInfoStyles.contentContainer}>
              <div className={raceInfoStyles.rules}>
                <h4>{topTitle}</h4>
                <p className={raceInfoStyles.raceName}>{raceName}</p>
                <div>{contentlist}</div>
              </div>
            </div>
          </div>
        </div>

      </LayoutA7>
    )
  }
}